var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('user-list-add-new',{attrs:{"is-add-new-user-sidebar-active":_vm.isAddNewUserSidebarActive,"role-options":_vm.roleOptions,"plan-options":_vm.planOptions},on:{"update:isAddNewUserSidebarActive":function($event){_vm.isAddNewUserSidebarActive=$event},"update:is-add-new-user-sidebar-active":function($event){_vm.isAddNewUserSidebarActive=$event},"refetch-data":_vm.refetchData}}),_c('users-list-filters',{attrs:{"role-filter":_vm.roleFilter,"plan-filter":_vm.planFilter,"status-filter":_vm.statusFilter,"role-options":_vm.roleOptions,"plan-options":_vm.planOptions,"status-options":_vm.statusOptions},on:{"update:roleFilter":function($event){_vm.roleFilter=$event},"update:role-filter":function($event){_vm.roleFilter=$event},"update:planFilter":function($event){_vm.planFilter=$event},"update:plan-filter":function($event){_vm.planFilter=$event},"update:statusFilter":function($event){_vm.statusFilter=$event},"update:status-filter":function($event){_vm.statusFilter=$event}}}),_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('div',{staticClass:"m-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-start mb-1 mb-md-0",attrs:{"cols":"12","md":"6"}},[_c('label',[_vm._v("Show")]),_c('v-select',{staticClass:"per-page-selector d-inline-block mx-50",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.perPageOptions,"clearable":false},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}}),_c('label',[_vm._v("entries")])],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('b-form-input',{staticClass:"d-inline-block mr-1",attrs:{"placeholder":"Search..."},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),(
                _vm.totalOfflineClient === 5 ||
                (_vm.totalOfflineClient > 5 && _vm.pkg_detail.title === 'Basic')
              )?_c('div',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],attrs:{"id":"popover-button-danger","variant":"outline-danger"}},[_vm._v(" Max 5 client limit reached ")]),_c('b-popover',{attrs:{"target":"popover-button-danger","variant":"danger","triggers":"focus","placement":"top"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',[_vm._v("Upgrade your package!")])]},proxy:true}],null,false,1601546332)},[_c('span',[_vm._v("Your plan limit of 5 client is reached. Please upgrade your plan or remove the existing clients")])])],1):_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){_vm.isAddNewUserSidebarActive = true}}},[_c('span',{staticClass:"text-nowrap"},[_vm._v("Add User")])])],1)])],1)],1),_c('b-table',{ref:"refUserListTable",staticClass:"position-relative",attrs:{"items":_vm.fetchUsers,"responsive":"","fields":_vm.tableColumns,"primary-key":"id","sort-by":_vm.sortBy,"show-empty":"","empty-text":"No matching records found","sort-desc":_vm.isSortDirDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.isSortDirDesc=$event},"update:sort-desc":function($event){_vm.isSortDirDesc=$event}},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',[_vm._v("Loading...")])],1)]},proxy:true},{key:"cell(user)",fn:function(data){return [_c('b-media',{attrs:{"vertical-align":"center"},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{attrs:{"size":"32","src":data.item.avatar,"text":_vm.avatarText(data.item.fullame),"variant":("light-" + (_vm.resolveUserRoleVariant(data.item.role))),"to":{
                name: 'apps-users-view',
                params: { id: data.item.traineelist.id },
              }}})]},proxy:true}],null,true)},[_c('b-link',{staticClass:"font-weight-bold d-block text-nowrap",attrs:{"to":{
              name: 'apps-users-view',
              params: { id: data.item.traineelist.id },
            }}},[_vm._v(" "+_vm._s(data.item.fullname)+" ")]),_c('small',{staticClass:"text-muted"},[_vm._v("@"+_vm._s(data.item.username))])],1)]}},{key:"cell(role)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_c('feather-icon',{staticClass:"mr-50",class:("text-" + (_vm.resolveUserRoleVariant(data.item.role))),attrs:{"icon":_vm.resolveUserRoleIcon(data.item.role),"size":"18"}}),_c('span',{staticClass:"align-text-top text-capitalize"},[_vm._v(_vm._s(data.item.role))])],1)]}},{key:"cell(status)",fn:function(data){return [_c('b-badge',{staticClass:"text-capitalize",attrs:{"pill":"","variant":("light-" + (_vm.resolveUserStatusVariant(
            data.item.traineelist.status
          )))}}),_vm._v(" "+_vm._s(data.item.traineelist.status)+" ")]}},{key:"cell(type)",fn:function(data){return [_c('b-badge',{attrs:{"pill":"","variant":("light-" + (data.item.traineelist.is_offline ? 'dark' : 'success'))}},[_vm._v(_vm._s(data.item.traineelist.is_offline ? "Offline" : "Online"))])]}},{key:"cell(expiry)",fn:function(data){return [(!data.item.traineelist.is_offline)?_c('div',[(
              _vm.timeCalc(data.item.traineelist.user_subscriptions[0].end_date)
            )?_c('div',[_c('b-badge',{attrs:{"pill":"","variant":"light-danger"}},[_vm._v(" Expired on "+_vm._s(data.item.traineelist.user_subscriptions[0].end_date))])],1):_vm._e(),(
              _vm.timeCalc(
                data.item.traineelist.user_subscriptions[0].end_date
              ) === false
            )?_c('div',[_c('b-badge',{attrs:{"pill":"","variant":"light-primary"}},[_vm._v(" "+_vm._s(_vm._f("moment")(data.item.traineelist.user_subscriptions[0].end_date,"from", "now", true))+" remaining")])],1):_vm._e()]):_vm._e(),(data.item.traineelist.is_offline)?_c('div',[(
              _vm.timeCalc(data.item.traineelist.end_date)
            )?_c('div',[_c('b-badge',{attrs:{"pill":"","variant":"light-danger"}},[_vm._v(" Expired on "+_vm._s(data.item.traineelist.end_date))])],1):_vm._e(),(
              _vm.timeCalc(
                data.item.traineelist.end_date
              ) === false
            )?_c('div',[_c('b-badge',{attrs:{"pill":"","variant":"light-primary"}},[_vm._v(" "+_vm._s(_vm._f("moment")(data.item.traineelist.end_date,"from", "now", true))+" remaining")])],1):_vm._e()]):_vm._e()]}},{key:"cell(actions)",fn:function(data){return [_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"flat-primary","to":{
            name: 'apps-users-view',
            params: { id: data.item.traineelist.id },
          }}},[_vm._v(" View Dashboard ")]),(data.item.traineelist.is_offline)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"flat-danger"},on:{"click":function($event){return _vm.deleteUserModal(data.item.traineelist.id)}}},[_vm._v(" X ")]):_vm._e()]}}])}),_c('div',{staticClass:"mx-2 mb-2"},[_c('b-row',[_c('b-col',{staticClass:"\n            d-flex\n            align-items-center\n            justify-content-center justify-content-sm-start\n          ",attrs:{"cols":"12","sm":"6"}},[_c('span',{staticClass:"text-muted"},[_vm._v("Showing "+_vm._s(_vm.dataMeta.from)+" to "+_vm._s(_vm.dataMeta.to)+" of "+_vm._s(_vm.dataMeta.of)+" entries")])]),_c('b-col',{staticClass:"\n            d-flex\n            align-items-center\n            justify-content-center justify-content-sm-end\n          ",attrs:{"cols":"12","sm":"6"}},[_c('b-pagination',{staticClass:"mb-0 mt-1 mt-sm-0",attrs:{"total-rows":_vm.totalUsers,"per-page":_vm.perPage,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}]),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }